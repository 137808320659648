import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import LogoSvg from '../../atoms/logoSvg'
import useRouteParam from '../../../hooks/useRouteParam'

const Wrapper = styled.header`
  height: 80px;
  background: #ffffff;
  display: flex;

  .inner {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .logo-link {
      height: 35px;
      display: inline-flex;
      align-items: center;

      .logo {
        margin: 0 5px 0 0;
        padding: 0;
        width: 170px;
      }
    }

    .btn-wrap {
      width: 550px;
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 260px;
        height: 46px;
        padding: 0 20px 1px 0;
        color: #ffffff;
        background: #ff584d;
        border-radius: 5px;
        font-weight: 800;
        font-size: 1.142em;
        text-decoration: underline solid rgba(255, 255, 255, 0) !important;

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 8px;
          border-color: transparent transparent transparent #ffffff;
          position: absolute;
          top: 50%;
          right: 30px;
          margin-top: -6px;
        }

        &.head-link02 {
          color: #243650;
          background: #ffe964;

          &:before {
            border-color: transparent transparent transparent #243650;
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    .inner {
      width: 100vw;

      .btn-wrap {
        width: calc(100vw - 200px);
        justify-content: flex-end;

        a {
          width: 40%;
          margin: 0 20px 0 0;

          &:before {
            right: 15px;
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    height: 50px;

    .inner {
      align-items: center;

      .logo-link {
        .logo {
          width: 130px;
        }
      }

      .btn-wrap {
        width: calc(100vw - 150px);

        a {
          font-size: 0.942em;
          width: 50%;
          max-width: 200px;
          height: 30px;
          margin: 0 10px 0 0;

          span {
            display: none;
          }

          &:before {
            right: 10px;
          }
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算ヘッダー
 * @constructor
 */
const LpExpenseHeader01 = (): JSX.Element => (
  <Wrapper>
    <div className="inner">
      <h1>
        <Link to={useRouteParam('/')} className="logo-link">
          <LogoSvg />
        </Link>
      </h1>
      <div className="btn-wrap">
        <Link
          to={useRouteParam(
            'https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp_expense&utm_medium=button&tran_source=expense'
          )}
          target="_blank"
          className="head-link01"
        >
          <span>無料</span>アカウント作成
        </Link>
        <Link
          to={useRouteParam('#lp-download')}
          target="_blank"
          className="head-link02 lp01-download-move"
        >
          お問い合わせ
        </Link>
      </div>
    </div>
  </Wrapper>
)

export default LpExpenseHeader01
