import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  background-size: 100%;
  background: #ffffff url(/images/lp/lp-expense/lpFvBg01.png) repeat-x center
    top;

  .inner {
    width: 1100px;
    padding: 50px 0 150px; // CTAかぶる分+100px
    position: relative;

    img {
      display: block;
      width: 100%;

      &.img-01 {
        width: 48%;
      }

      &.img-02 {
        position: absolute;
        left: -51%;
        top: 32%;
        width: 120%;
        z-index: 2;
      }

      &.img-03 {
        padding-top: 18%;
        width: 68%;
        position: relative;
        z-index: 1;
        left: 220px;
      }

      &.img-04 {
        width: 42%;
        position: absolute;
        right: -1%;
        top: 5%;
        z-index: 0;
      }

      &.img-05 {
        bottom: 140px;
        width: 230px;
        position: absolute;
        z-index: 1;
      }
    }
  }

  ${BreakPoints.xLarge} {
    .inner {
      img {
        &.img-05 {
          bottom: 100px;
          width: 170px;
        }
      }
    }
  }

  ${BreakPoints.large} {
  }

  ${BreakPoints.medium} {
    .inner {
      width: 100vw;
      padding: 20px 20px 150px; // CTAかぶる分+100px
      position: relative;

      img {
        &.img-01 {
          width: 52%;
        }

        &.img-02 {
          left: -48%;
          top: 24%;
        }

        &.img-03 {
          padding-top: 20%;
          width: calc(95% - 120px);
          margin: 0;
          left: 130px;
        }

        &.img-04 {
          width: 36%;
          right: 1%;
        }

        &.img-05 {
          bottom: 115px;
          width: 135px;
          left: 5px;
        }
      }
    }
  }
`

/**
 * LP-経費精算用ファーストビュー
 * @constructor
 */
const LpFvExpense01 = (): JSX.Element => (
  <Wrapper>
    <div className="inner pc">
      <img
        src="/images/lp/lp-expense/lpFvPc01.png"
        alt="電子帳簿保存法に対応した経費精算システム【キンクラ】"
        className="img-01"
      />
      <img
        src="/images/lp/lp-expense/lpFvPc02.png"
        alt="ペーパーレスで低コストで利用でキス経費精算システム【キンクラ】"
        className="img-02"
      />
      <img
        src="/images/lp/lp-expense/lpFvPc03.png"
        alt="追加料金0円／リモートワーク完全対応／勤怠管理もOK"
        className="img-03"
      />
      <img
        src="/images/lp/lp-expense/lpFvPc04.png"
        alt="テレワークするなら【キンクラ】"
        className="img-04"
      />
      <img
        src="/images/logo_jiima.png"
        alt="JIIMA認証取得"
        className="img-05"
      />
    </div>
  </Wrapper>
)

export default LpFvExpense01
