import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 170px 0 70px;
  background: #ffffff;

  .inner {
    position: relative;
    width: 1100px;
    border-radius: 30px;
    border: solid 3px #243650;

    h1 {
      text-align: center;
      position: absolute;
      top: -100px;
      width: 100%;

      img {
        width: 100%;
        max-width: 740px;
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      align-items: center;
      padding: 80px 60px 70px;

      img {
        width: auto;
        height: 93px;

        &.etc {
          height: 23px;
          margin: 15px 0 0 5px;
        }
      }
    }

    .details {
      text-align: center;
      position: relative;
      background: #ffffc0;
      border-radius: 0 0 27px 27px;
      padding: 70px 60px 50px;
      font-size: 18px;
      line-height: 1.8em;

      .ttl {
        position: absolute;
        text-align: center;
        top: -35px;
        width: calc(100% - 120px);
        z-index: 2;

        img {
          width: 100%;
          max-width: 670px;
        }
      }

      .strong {
        font-size: 30px;
        font-weight: bold;
        color: #19bab3;
        line-height: 2em;
        background: linear-gradient(
          transparent 40%,
          rgb(255, 255, 255) 41%,
          rgb(255, 255, 255) 100%
        );
        margin: 15px 20px 0;
      }
    }

    .img-item {
      display: block;
      position: absolute;
      max-width: 480px;
      width: 40vw;
      z-index: 1;
      right: -60px;
      top: 80px;
    }
  }

  ${BreakPoints.large} {
    .inner {
      .items {
        position: relative;
        z-index: 2;

        img {
          background: #ffffff;
        }
      }
    }
  }

  ${BreakPoints.medium} {
    padding: 120px 0 50px;

    .inner {
      width: 96vw;
      border-radius: 20px;
      border: solid 2px #243650;

      h1 {
        top: -60px;
        width: 100%;

        img {
          width: 90%;
        }
      }

      .items {
        gap: 10px;
        align-items: center;
        padding: 40px 60px 50px 30px;

        img {
          width: auto;
          height: 70px;

          &.etc {
            height: 23px;
            margin: 15px 0 0 5px;
          }
        }
      }

      .details {
        border-radius: 0 0 18px 18px;
        padding: 30px 30px 40px;
        font-size: 16px;

        .ttl {
          top: -30px;
          width: calc(100% - 60px);
        }

        .strong {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5em;
          background: none;
          text-decoration: underline 10px #ffffff;
          margin: 15px 10px 0;
        }
      }

      .img-item {
        max-width: 480px;
        width: 56vw;
        right: -20px;
        top: 120px;
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用コンテンツ01-お悩み
 * @constructor
 */
const LpExpenseContent01 = (): JSX.Element => (
  <Wrapper>
    <section className="inner">
      <h1>
        <img
          src="/images/lp/lp-expense/lpContent01-Ttl01.png"
          alt="キンクラとは"
        />
      </h1>
      <div className="items">
        <img
          src="/images/lp/lp-expense/lpContent01-01.png"
          alt="キンクラとは"
        />
        <img
          src="/images/lp/lp-expense/lpContent01-02.png"
          alt="キンクラとは"
        />
        <img
          src="/images/lp/lp-expense/lpContent01-03.png"
          alt="キンクラとは"
        />
        <img
          src="/images/lp/lp-expense/lpContent01-04.png"
          alt="キンクラとは"
        />
        <img
          src="/images/lp/lp-expense/lpContent01-05.png"
          alt="キンクラとは"
        />
        <img
          src="/images/lp/lp-expense/lpContent01-06.png"
          className="etc"
          alt="キンクラとは"
        />
      </div>
      <div className="details">
        <p className="ttl">
          <img
            src="/images/lp/lp-expense/lpContent01-07.png"
            alt="キンクラとは"
          />
        </p>
        <p>
          自社・在宅・客先常駐など勤務体系を問わずに一括管理できる便利な経費精算システムです。
          <br />
          紙での管理の不安や、紙での作業を煩わしく感じている
        </p>
        <p className="strong">
          企業の経営者様や、経理・労務部門の管理者様をサポートします！
        </p>
      </div>
      <img
        src="/images/lp/lp-expense/lpContent01-08.png"
        className="img-item"
        alt="キンクラとは"
      />
    </section>
  </Wrapper>
)

export default LpExpenseContent01
