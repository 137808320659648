import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 70px;
  background: #9ae1d7 url(/images/lp/lp-expense/lpSecurity01-bg02.png) repeat-x
    center center;

  .inner {
    position: relative;
    width: 1100px;
    background: url(/images/lp/lp-expense/lpSecurity01-bg01.png) no-repeat
      center top;
    background-size: contain;

    h1 {
      text-align: center;
      width: 100%;
      padding-top: 20px;

      img {
        width: 100%;
        max-width: 474px;
      }
    }

    .item-wrap {
      display: flex;
      gap: 35px;
      align-items: stretch;
      justify-content: stretch;
      padding: 30px 0 0;

      .item {
        border-radius: 10px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        background: #19bab3;
        padding: 8px;
        width: calc(100% / 4);
        display: flex;

        .border {
          border-radius: 3px;
          border: solid 3px #ffffff;
          padding: 20px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .icon {
        width: 100%;
        max-width: 135px;
        margin: 0 0 20px;
      }

      .label {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 20px;
      }

      .txt {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    padding: 0 0 50px;

    .inner {
      width: 100vw;
      background-size: 180%;

      h1 {
        img {
          width: 80%;
        }
      }

      .item-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 30px 0 0;

        .item {
          padding: 3px;
          width: calc(90vw / 2);
          display: flex;

          .border {
            border-radius: 3px;
            border: solid 3px #ffffff;
            padding: 20px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .icon {
          width: 100%;
          max-width: 135px;
          margin: 0 0 20px;
        }

        .label {
          width: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 20px;
        }

        .txt {
          font-size: 16px;
          margin: 0 0 20px;
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用-セキュリティ
 * @constructor
 */
const LpExpenseSecurity01 = (): JSX.Element => (
  <Wrapper>
    <section className="inner">
      <h1>
        <img
          src="/images/lp/lp-expense/lpSecurity01-ttl01.png"
          alt="キンクラとは"
        />
      </h1>
      <div className="item-wrap">
        {/** 1 */}
        <div className="item">
          <div className="border">
            <img
              src="/images/lp/lp-expense/lpSecurity01-icon01.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="label">情報資産の管理</div>
            <div className="txt">
              重要な情報は暗号化して保存。
              <br />
              万一障害発生時もデータはバックアップされているので安心です。
            </div>
          </div>
        </div>
        {/** 2 */}
        <div className="item">
          <div className="border">
            <img
              src="/images/lp/lp-expense/lpSecurity01-icon02.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="label">通信の暗号化</div>
            <div className="txt">
              ネットワーク通信はSSLで暗号化。
              <br />
              盗聴・漏えい等の脅威に対しデータの機密性を確保しています。
            </div>
          </div>
        </div>
        {/** 3 */}
        <div className="item">
          <div className="border">
            <img
              src="/images/lp/lp-expense/lpSecurity01-icon03.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="label">ISMS認証</div>
            <div className="txt">
              ISMS認証（ISO27001）とプライバシーマークを取得した企業がキンクラを開発・運営
            </div>
          </div>
        </div>
        {/** 4 */}
        <div className="item">
          <div className="border">
            <img
              src="/images/lp/lp-expense/lpSecurity01-icon04.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="label">脆弱性診断クリア</div>
            <div className="txt">
              キンクラは第三者企業による脆弱性診断をクリアしています。
            </div>
          </div>
        </div>
      </div>
    </section>
  </Wrapper>
)

export default LpExpenseSecurity01
