import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 120px;
  background: #ffffff url(/images/lp/lp-expense/lpContent04-Bg01.png) repeat-x
    center bottom;

  .inner {
    position: relative;
    width: 1100px;

    h1 {
      text-align: center;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .item-wrap {
      display: flex;
      gap: 35px;
      align-items: stretch;
      justify-content: stretch;
      padding: 70px 0 0;

      .item {
        border-radius: 30px;
        border: solid 3px #7ad8cb;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        background: #e6f8f5;
        padding: 0 20px 50px;
        position: relative;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .label {
        position: relative;
        top: -3px;
        width: 100%;
        max-width: 190px;
      }

      .balloon {
        width: 100%;
        max-width: 214px;
        margin: 20px 0;
      }

      .icon-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .icon {
        width: 100%;
        max-width: 145px;
        margin: 0 0 20px;
      }

      .txt {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin: 0 0 20px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        font-size: 18px;

        span {
          color: #ff6969;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    background-size: 150%;
    padding: 0 0 60px;

    .inner {
      position: relative;
      width: 96vw;

      .item-wrap {
        flex-direction: column;
        align-items: center;
        padding: 30px 0 0;

        .item {
          border-radius: 20px;
          border: none;
          padding: 0 20px 50px;
          width: 100%;
        }

        .label {
          position: relative;
          top: -3px;
          width: 100%;
          max-width: 190px;
        }

        .balloon {
          width: 100%;
          max-width: 214px;
          margin: 20px 0;
        }

        .icon-wrap {
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        }

        .icon {
          width: 100%;
          max-width: 145px;
          margin: 0 0 20px;
        }

        .txt {
          font-size: 24px;
          text-align: left;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用コンテンツ04-導入メリット
 * @constructor
 */
const LpExpenseContent04 = (): JSX.Element => (
  <Wrapper>
    <section className="inner">
      <h1>
        <img
          src="/images/lp/lp-expense/lpContent04-ttl01.png"
          alt="キンクラとは"
        />
      </h1>
      <div className="item-wrap">
        {/** 1 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpContent04-label01.png"
            alt="キンクラとは"
            className="label"
          />
          <img
            src="/images/lp/lp-expense/lpContent04-01.png"
            alt="キンクラとは"
            className="balloon"
          />
          <div className="icon-wrap">
            <img
              src="/images/lp/lp-expense/lpContent04-icon01.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="txt">
              十人十色の
              <br />
              シフト管理も
              <br />
              ラクラク
            </div>
          </div>
          <p>
            変形労働時間制を採用していて夜勤、日勤、時短など…シフトが全員バラバラだと、労務管理者が従業員全員のシフトスケジュールを用意するには限界があります。
            <br />
            キンクラなら、従業員が自分自身のシフトを自由に作成することができ、労務管理者は承認するだけで良いため、“シフト管理の業務効率が飛躍的に向上”します。
          </p>
        </div>
        {/** 2 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpContent04-label02.png"
            alt="キンクラとは"
            className="label"
          />
          <img
            src="/images/lp/lp-expense/lpContent04-02.png"
            alt="キンクラとは"
            className="balloon"
          />
          <div className="icon-wrap">
            <img
              src="/images/lp/lp-expense/lpContent04-icon02.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="txt">
              有給取得や
              <br />
              残業時間など社員の
              <br />
              状況を一元管理
            </div>
          </div>
          <p>
            変形労働時間制を採用していて夜勤、日勤、時短など…シフトが全員バラバラだと、労務管理者が従業員全員のシフトスケジュールを用意するには限界があります。
            <br />
            キンクラなら、従業員が自分自身のシフトを自由に作成することができ、労務管理者は承認するだけで良いため、“シフト管理の業務効率が飛躍的に向上”します。
          </p>
        </div>
        {/** 3 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpContent04-label03.png"
            alt="キンクラとは"
            className="label"
          />
          <img
            src="/images/lp/lp-expense/lpContent04-03.png"
            alt="キンクラとは"
            className="balloon"
          />
          <div className="icon-wrap">
            <img
              src="/images/lp/lp-expense/lpContent04-icon03.png"
              alt="キンクラとは"
              className="icon"
            />
            <div className="txt">
              給与計算時間を
              <br />
              大幅削減できる
            </div>
          </div>
          <p>
            変形労働時間制を採用していて夜勤、日勤、時短など…シフトが全員バラバラだと、労務管理者が従業員全員のシフトスケジュールを用意するには限界があります。
            <br />
            キンクラなら、従業員が自分自身のシフトを自由に作成することができ、労務管理者は承認するだけで良いため、“シフト管理の業務効率が飛躍的に向上”します。
          </p>
        </div>
      </div>
    </section>
  </Wrapper>
)

export default LpExpenseContent04
