import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 90px;
  background: #ffffff url(/images/lp/lp-expense/lpPlan01-bg01.png) no-repeat
    center center;

  .inner {
    position: relative;
    width: 1100px;

    h1 {
      text-align: center;
      width: 100%;
      padding: 60px 0 40px;

      img {
        width: 100%;
        max-width: 280px;
      }
    }

    .item-wrap {
      display: flex;
      gap: 35px;
      align-items: stretch;
      justify-content: stretch;
      padding: 0 0 0 65px;
      position: relative;

      .item {
        border-radius: 25px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        background: #19bab3;
        width: calc(100% / 3);
        max-width: 296px;
        // height: 348px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .label {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px 25px 0 0;
        text-align: center;

        img {
          height: 27px;
        }
      }

      .icon {
        display: flex;
        width: 100%;
        margin: 20px auto;
        justify-content: center;
        align-items: center;
        height: 86px;

        img {
          width: 100%;
          display: block;
          max-width: 220px;
        }
      }

      .txt {
        font-size: 16px;
        margin: 0 30px 30px;
        line-height: 1.6em;
      }

      .plus {
        position: absolute;
        top: -56px;
        width: 82px;
        left: calc(50% - 42px);
      }

      .bottom {
        background: #e8f5f9;
        border-radius: 0 0 25px 25px;

        img {
          width: 100%;
          display: block;
          max-width: 220px;
          margin: 20px auto;
        }
      }

      .free {
        background: #eaebee;

        .label {
          background: #0bbbbb;
        }
      }

      .business {
        background: #ffffff;

        .label {
          background: #6dbbd8;
        }
      }

      .enterprise {
        background: #ffffff;

        .label {
          background: #2261c8;
        }

        .bottom {
          background: #d4e4f9;
        }
      }

      .plan-type {
        position: absolute;
        height: 240px;
        width: 90%;
        background: #999999;
        border-radius: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        z-index: 1;
        left: 0;
        bottom: 20px;

        img {
          width: 27px;
        }
      }

      &.option {
        margin-top: 30px;

        .icon {
          margin: 30px auto 10px;
        }

        .txt {
          display: flex;
          width: 100%;
          margin: 0 0 20px;
          height: 52px;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .plan-type {
          background: #fbb03b;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    background: #ffffff url(/images/lp/lp-expense/lpPlan01-bg01.png) no-repeat
      center bottom;
    background-size: 100%;

    .inner {
      width: 100vw;

      h1 {
        text-align: center;
        width: 100%;
        padding: 30px 0 20px;

        img {
          width: 40%;
        }
      }

      .item-wrap {
        gap: 10px;
        padding: 0 10px 0 40px;

        .item {
          border-radius: 15px;
          background: #19bab3;
          width: calc(100% / 2);
          min-width: 100px;
        }

        .label {
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px 15px 0 0;
          text-align: center;

          img {
            display: none;
          }

          span {
            display: block;
            font-size: 28px;
            font-weight: bold;
            color: #ffffff;
          }
        }

        .icon {
          height: 60px;
        }

        .txt {
          display: none;
        }

        .plus {
          top: -28px;
          width: 42px;
          left: calc(50% - 21px);
        }

        .bottom {
          border-radius: 0 0 10px 10px;

          img {
            width: 90%;
          }
        }

        .free {
          width: calc(100% / 6);
          background: #eaebee;
        }

        .business {
          background: #ffffff;
        }

        .enterprise {
          background: #ffffff;
        }

        .plan-type {
          position: absolute;
          height: 160px;
          width: 90%;
          background: #999999;
          border-radius: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 10px;
          bottom: 10px;

          img {
            width: 18px;
          }
        }

        &.option {
          margin-top: 10px;

          .txt {
            width: 100%;
            height: 30px;
            font-size: 12px;
          }
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用-料金プラン
 * @constructor
 */
const LpExpensePlan01 = (): JSX.Element => (
  <Wrapper>
    <section className="inner">
      <h1>
        <img
          src="/images/lp/lp-expense/lpPlan01-ttl01.png"
          alt="キンクラとは"
        />
      </h1>
      {/** 基本料金 */}
      <div className="item-wrap basic">
        {/** free */}
        <div className="item free">
          <div className="label">
            <img
              src="/images/lp/lp-expense/lpPlan01-label01.png"
              alt="キンクラとは"
            />
            <span>Free</span>
          </div>
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-free01.png"
              alt="キンクラとは"
            />
          </div>
          <div className="txt">
            スタートアップ・特定のグループ内で従業員向け勤怠機能を利用したい場合におすすめのプランです 
          </div>
        </div>
        {/** business */}
        <div className="item business">
          <div className="label">
            <img
              src="/images/lp/lp-expense/lpPlan01-label02.png"
              alt="キンクラとは"
            />
            <span>Business</span>
          </div>
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-business01.png"
              alt="キンクラとは"
            />
          </div>
          <div className="txt">
            管理職向け機能も活用し、勤怠業務効率を改善されたい中・小規模の企業におすすめのプランです
          </div>
        </div>
        {/** enterprise */}
        <div className="item enterprise">
          <div className="label">
            <img
              src="/images/lp/lp-expense/lpPlan01-label03.png"
              alt="キンクラとは"
            />
            <span>Enterprise</span>
          </div>
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-enterprise01.png"
              alt="キンクラとは"
            />
          </div>
          <div className="txt">
            キンクラをフル活用されたい大規模企業または自社独自の機能をご利用されたい場合におすすめのプランです
          </div>
        </div>
        <div className="plan-type">
          <img
            src="/images/lp/lp-expense/lpPlan01-label04.png"
            alt="キンクラとは"
          />
        </div>
      </div>
      {/** オプション料金 */}
      <div className="item-wrap option">
        {/** free */}
        <div className="item free">
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-free02.png"
              alt="キンクラとは"
            />
          </div>
        </div>
        {/** business */}
        <div className="item business">
          <img
            src="/images/lp/lp-expense/lpPlan01-icon01.png"
            alt="キンクラとは"
            className="plus"
          />
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-business02.png"
              alt="キンクラとは"
            />
          </div>
          <div className="txt">基本申請数：50件</div>
          <div className="bottom">
            <img
              src="/images/lp/lp-expense/lpPlan01-business03.png"
              alt="キンクラとは"
            />
          </div>
        </div>
        {/** enterprise */}
        <div className="item enterprise">
          <img
            src="/images/lp/lp-expense/lpPlan01-icon01.png"
            alt="キンクラとは"
            className="plus"
          />
          <div className="icon">
            <img
              src="/images/lp/lp-expense/lpPlan01-enterprise02.png"
              alt="キンクラとは"
            />
          </div>
          <div className="txt">
            基本申請数：50件
            <br />
            ※利用人数に応じて加算
          </div>
          <div className="bottom">
            <img
              src="/images/lp/lp-expense/lpPlan01-enterprise03.png"
              alt="キンクラとは"
            />
          </div>
        </div>
        <div className="plan-type">
          <img
            src="/images/lp/lp-expense/lpPlan01-label05.png"
            alt="キンクラとは"
          />
        </div>
      </div>
    </section>
  </Wrapper>
)

export default LpExpensePlan01
