import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import LogoSvg from '../atoms/logoSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.footer`
  background: #ffffff;
  text-align: center;
  padding: 15px 0 10px;
  .logo-link {
    height: 35px;
    display: inline-flex;
    align-items: center;
    .logo {
      margin: 0 5px 0 0;
      padding: 0;
      width: 170px;
    }
  }
  .copyright {
    padding: 0 0;
    font-size: 12px;
    color: #333333;
  }
  ${BreakPoints.xLarge} {
  }
  ${BreakPoints.medium} {
  }
  ${BreakPoints.small} {
  }
`

/**
 * LP01フッター
 * @constructor
 */
const LpFooter01 = (): JSX.Element => (
  <Wrapper>
    <h1>
      <Link to={useRouteParam('/')} className="logo-link">
        <LogoSvg />
      </Link>
    </h1>
    <p className="copyright">
      &copy;2021 CLINKS Co., Ltd. All Rights Reserved.
    </p>
  </Wrapper>
)

export default LpFooter01
