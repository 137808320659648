import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 100px;
  background: #9ae1d7 url(/images/lp/lp-expense/lpContent03-Bg01.png) repeat-x
    center -150px;

  .inner {
    position: relative;
    max-width: 1100px;
    width: 96vw;
    border-radius: 30px;
    background: #ffffff;
    padding: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    .border {
      border-radius: 22px;
      border: solid 3px #243650;
    }

    h1 {
      text-align: center;
      width: 100%;
      padding: 80px 60px 60px;

      img {
        width: 100%;
        max-width: 522px;
      }
    }

    .item {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      .txt {
        padding: 0 20px 0 50px;

        img {
          width: 100%;
          display: block;
          padding-bottom: 20px;
        }

        p {
          font-size: 18px;
          width: 100%;
          max-width: 450px;
          line-height: 1.7em;
        }
      }

      .image {
        display: block;
        max-width: 370px;
        margin: 0 30px;
        height: 100%;
      }

      .txt + .image {
        margin-left: -10px;
      }

      .image + .txt {
        margin-left: -30px;
      }
    }

    .item01 {
      .txt {
        img {
          max-width: 480px;
        }
      }
    }

    .item02 {
      .txt {
        img {
          max-width: 480px;
        }
      }
    }

    .item03 {
      .txt {
        img {
          max-width: 550px;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    padding: 30px 0 50px;
    background: #9ae1d7 url(/images/lp/lp-expense/lpContent03-Bg01.png) repeat-x
      center top;
    background-size: contain;

    .inner {
      width: 94vw;
      border-radius: 10px;
      padding: 4px;

      .border {
        border-radius: 6px;
        border: solid 2px #243650;
      }

      h1 {
        padding: 30px;
      }

      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        justify-content: center;
        align-items: center;

        .txt {
          padding: 0 20px 20px;

          img {
            padding-bottom: 20px;
          }

          p {
            font-size: 16px;
          }
        }

        .image {
          margin: 0;
          height: 50%;
          width: 50%;
        }

        .txt + .image {
          margin-left: 0;
        }

        .image + .txt {
          margin-left: 0;
        }
      }

      .item02 {
        .image {
          order: 2;
        }

        .txt {
          order: 1;
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用コンテンツ03-選ばれる理由
 * @constructor
 */
const LpExpenseContent03 = (): JSX.Element => (
  <Wrapper>
    <section className="inner">
      <div className="border">
        <h1>
          <img
            src="/images/lp/lp-expense/lpContent03-ttl01.png"
            alt="キンクラとは"
          />
        </h1>
        {/** 1 */}
        <div className="item item01">
          <div className="txt">
            <img
              src="/images/lp/lp-expense/lpContent03-01.png"
              alt="キンクラとは"
            />
            <p>
              電子帳簿保存法の要件を満たしているシステムのため、解像度や履歴管理、検索など申請の度に電帳法の要件適合をチェックする必要はありません。
            </p>
          </div>
          <img
            src="/images/lp/lp-expense/lpContent03-02.png"
            alt="キンクラとは"
            className="image"
          />
        </div>
        {/** 2 */}
        <div className="item item02">
          <img
            src="/images/lp/lp-expense/lpContent03-04.png"
            alt="キンクラとは"
            className="image"
          />
          <div className="txt">
            <img
              src="/images/lp/lp-expense/lpContent03-03.png"
              alt="キンクラとは"
            />
            <p>
              スマホ撮影やスキャナから取り込んだデータの保存にも対応しているので、今まで発生していた書類管理の手間を軽減し、申請／承認／管理が簡単に行なえます。
            </p>
          </div>
        </div>
        {/** 3 */}
        <div className="item item03">
          <div className="txt">
            <img
              src="/images/lp/lp-expense/lpContent03-05.png"
              alt="キンクラとは"
            />
            <p>
              勤務表と交通費の提出、案件や顧客管理など経費精算、勤怠管理、案件管理が1つのシステムで完結。
              <br />
              貴社の様々な業務をサポートします。
            </p>
          </div>
          <img
            src="/images/lp/lp-expense/lpContent03-06.png"
            alt="キンクラとは"
            className="image"
          />
        </div>
      </div>
    </section>
  </Wrapper>
)

export default LpExpenseContent03
