import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  background: #ccf0eb;

  h1 {
    text-align: center;
    width: 100%;
    position: relative;
    background: #19bab3;
    padding: 0;
    z-index: 1;

    img {
      margin: -40px 0 -7px 5px;
      width: 100%;
      max-width: 1180px;
    }
  }

  .inner {
    position: relative;
    width: 1100px;
    background: #ffffff;
    box-shadow: 5px -2px 5px rgba(0, 0, 0, 0.1),
      -5px -2px 5px rgba(0, 0, 0, 0.2);
    padding: 30px 10px;

    .function-ttl {
      text-align: center;
      margin: 40px 0;

      img {
        width: 100%;
        max-width: 414px;
      }
    }

    .item-wrap {
      display: flex;

      .item {
        padding: 0 30px;
        border-right: dotted 3px #cccccc;
        width: calc(100% / 4);
        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-of-type {
          border-right: none;
        }
      }

      .icon {
        width: 100%;
        max-width: 135px;
        margin: 0 0 20px;
      }

      .label {
        width: 100%;
        text-align: center;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 20px;
      }

      .txt {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }

    .function-sub-label {
      display: inline-flex;
      width: 50%;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      p {
        width: 100%;
        max-width: 268px;
        height: 44px;
        line-height: 44px;
        font-size: 26px;
        font-weight: bold;
        border-radius: 100vh;
        border: solid 1px #3ec7b3;
        color: #3ec7b3;
        text-align: center;
      }
    }

    .appeal {
      border-radius: 100vh;
      padding: 40px;
      margin: 60px auto 30px;
      background: #e6f8f5;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      max-width: 890px;
      letter-spacing: 0.04em;

      span {
        font-size: 26px;
        color: #ff6969;
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    h1 {
      img {
        margin: -10px 0 -7px -9%;
        width: 118%;
      }
    }

    .inner {
      width: 94vw;
      padding: 0;

      .function-ttl {
        text-align: center;
        margin: 20px 0;
      }

      .item-wrap {
        display: flex;
        flex-wrap: wrap;

        .item {
          padding: 0 30px;
          border-right: none;
          width: calc(100% / 2);
          display: flex;
          flex-direction: column;
          align-items: center;

          &:nth-of-type(1),
          &:nth-of-type(3) {
            border-right: dotted 3px #cccccc;
          }

          &:nth-of-type(1),
          &:nth-of-type(2) {
            border-bottom: dotted 3px #cccccc;
          }

          &:nth-of-type(3),
          &:nth-of-type(4) {
            padding-top: 20px;
          }
        }

        .icon {
          width: 100%;
          max-width: 135px;
          margin: 0 0 20px;
        }

        .label {
          width: 100%;
          text-align: center;
          height: 40px;
          font-size: 18px;
          margin-bottom: 15px;
        }

        .txt {
          font-size: 14px;
          margin: 0 0 15px;
        }
      }

      .function-sub-label {
        display: none;
      }

      .appeal {
        padding: 20px 40px;
        margin: 30px auto;
        width: 90%;
        font-size: 18px;

        span {
          display: block;
          font-size: 22px;
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用-機能一覧
 * @constructor
 */
const LpExpenseFunction01 = (): JSX.Element => (
  <Wrapper>
    <h1>
      <img
        src="/images/lp/lp-expense/lpFunction01-ttl01.png"
        alt="キンクラとは"
      />
    </h1>
    <section className="inner">
      {/** 経費精算機能 */}
      <div className="function-ttl">
        <img
          src="/images/lp/lp-expense/lpFunction01-ttl02.png"
          alt="キンクラとは"
        />
      </div>
      <div className="item-wrap">
        {/** 1 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon01.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">
            タイム
            <br />
            スタンプの付与
          </div>
          <div className="txt">
            領収書アップロード時に自動的にタイムスタンプを付与
          </div>
        </div>
        {/** 2 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon02.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">検索機能</div>
          <div className="txt">申請データは日付・金額・利用先で検索が可能</div>
        </div>
        {/** 3 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon03.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">
            領収書の
            <br />
            履歴管理
          </div>
          <div className="txt">データの訂正・追加・削除の履歴を確認可能</div>
        </div>
        {/** 4 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon04.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">
            ファイル
            <br />
            要件の自動判定
          </div>
          <div className="txt">
            保存要件に適合したファイルか自動判定し、要件を満たしていない場合は警告を表示
          </div>
        </div>
      </div>
      {/** 経費精算機能 */}
      <div className="function-ttl">
        <img
          src="/images/lp/lp-expense/lpFunction01-ttl03.png"
          alt="キンクラとは"
        />
      </div>
      <div className="function-sub-label">
        <p>従業員向け機能</p>
      </div>
      <div className="function-sub-label">
        <p>従業員向け機能</p>
      </div>
      <div className="item-wrap">
        {/** 1 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon05.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">打刻</div>
          <div className="txt">
            PC・タブレット・スマホから簡単に打刻ができます
          </div>
        </div>
        {/** 2 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon06.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">勤務表</div>
          <div className="txt">自社だけでなく、常駐先の勤務表も自動生成</div>
        </div>
        {/** 3 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon07.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">残業管理</div>
          <div className="txt">残業時間や勤務時間を一覧にして管理</div>
        </div>
        {/** 4 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon08.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">有給管理</div>
          <div className="txt">全ユーザーの休暇取得状況を自動集計</div>
        </div>
      </div>
      {/** 人事労務向け機能 */}
      <div className="function-ttl">
        <img
          src="/images/lp/lp-expense/lpFunction01-ttl04.png"
          alt="キンクラとは"
        />
      </div>
      <div className="item-wrap">
        {/** 1 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon09.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">代理申請</div>
          <div className="txt">労務管理社が打刻や各種申請提出を代行</div>
        </div>
        {/** 2 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon10.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">休暇付与</div>
          <div className="txt">有給の自動付与や任意に休暇付与が可能</div>
        </div>
        {/** 3 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon11.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">給与ソフト連携</div>
          <div className="txt">
            給与ソフトに合わせてエクスポートデータを生成
          </div>
        </div>
        {/** 4 */}
        <div className="item">
          <img
            src="/images/lp/lp-expense/lpFunction01-icon12.png"
            alt="キンクラとは"
            className="icon"
          />
          <div className="label">申請承認</div>
          <div className="txt">各種申請の承認、差し戻し、一括操作</div>
        </div>
      </div>
      <div className="appeal">
        これらの機能はほんの一部。
        <br />
        貴社をサポートする様々な機能が
        <span>今なら無料でお使いいただけます</span>
      </div>
    </section>
  </Wrapper>
)

export default LpExpenseFunction01
