import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import useRouteParam from '../../../hooks/useRouteParam'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  margin-top: -100px;

  .inner {
    position: relative;
    width: 96vw;
    max-width: 1280px;
    border-radius: 30px;
    background: #19bab3;
    padding: 8px;

    .border {
      border-radius: 22px;
      border: solid 3px #ffffff;
      padding: 30px;
    }

    img {
      width: 100%;
    }

    h1 {
      width: 100%;
      max-width: 840px;
      margin: 0 auto 30px;
      text-align: center;
    }

    .btn-area {
      width: 50%;
      padding: 0;
      display: inline-block;
      text-align: center;

      img {
        width: 80%;

        &.btn {
          width: 100%;
          max-width: 529px;
          margin-top: 10px;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    .inner {
      border-radius: 10px;
      padding: 3px;

      .border {
        border-radius: 7px;
        border: solid 1px #ffffff;
        padding: 10px 10px 30px;
      }

      h1 {
        margin: -30px auto 0;
      }

      .btn-area {
        width: 90%;
        margin: 0 5%;
        padding: 20px 0 0;

        img {
          width: 100%;

          &.btn {
            margin-top: 5px;
          }
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP-経費精算用CTA
 * @constructor
 */
const LpExpenseCta01 = (): JSX.Element => (
  <Wrapper className="cta">
    <section className="inner">
      <div className="border">
        <h1>
          <img
            src="/images/lp/lp-expense/lpCta01.png"
            alt="今なら全機能が無料で使える！"
            className="cta-01"
          />
        </h1>
        <div className="btn-area btn-01">
          <img
            src="/images/lp/lp-expense/lpCta02.png"
            alt="早速トライアルでキンクラを使ってみる"
            className="cta-02"
          />
          <Link
            to={useRouteParam(
              'https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp_expense&utm_medium=button&tran_source=expense'
            )}
            target="_blank"
            className="cta-btn01 lp01-account-move"
          >
            <img
              src="/images/lp/lp-expense/lpCtaBtn01.png"
              alt="無料アカウントの作成はこちら"
              className="btn"
            />
          </Link>
        </div>
        <div className="btn-area btn-02">
          <img
            src="/images/lp/lp-expense/lpCta03.png"
            alt="キンクラをわかりやすくまとめた資料はコチラ"
            className="cta-03"
          />
          <Link
            to={useRouteParam('#lp-download')}
            className="cta-btn02 lp01-download-move"
          >
            <img
              src="/images/lp/lp-expense/lpCtaBtn02.png"
              alt="無料で資料をダウンロードする"
              className="btn"
            />
          </Link>
        </div>
      </div>
    </section>
  </Wrapper>
)

export default LpExpenseCta01
