import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  background-size: 100%;
  background: #ffffff url(/images/lp/lp-expense/lpFvBg01.png) repeat-x center
    top;

  .inner {
    width: 1200px;
    padding: 30px 0;
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 100%;

      &.img-01 {
        width: 52%;
        height: auto;
      }

      &.img-02 {
        position: relative;
        right: -2%;
        top: 0;
        width: 45%;
      }
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.large} {
  }

  ${BreakPoints.medium} {
  }
`

/**
 * LP-経費精算用ラストコンテンツ
 * @constructor
 */
const LpExpenseLast01 = (): JSX.Element => (
  <Wrapper>
    <div className="inner">
      <img
        src="/images/lp/lp-expense/lpLast01-01.png"
        alt="電子帳簿保存法に対応した経費精算システム【キンクラ】"
        className="img-01"
      />
      <img
        src="/images/lp/lp-expense/lpLast01-02.png"
        alt="ペーパーレスで低コストで利用でキス経費精算システム【キンクラ】"
        className="img-02"
      />
    </div>
  </Wrapper>
)

export default LpExpenseLast01
